/** @jsx jsx */
import { jsx } from "theme-ui"

const Blog = () => {

  return (
    <div>
      Blog
    </div>
  )
}

export default Blog